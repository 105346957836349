import { graphql, Link, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { Banner } from "../style/generic"
import { HeaderContainer } from "../style/header"
import HeaderArticle from "./headerArticle"

//@ts-ignore
const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      image: file(relativePath: { eq: "running-man.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <HeaderContainer>
      <div>
        <Banner>
          <p>
            Big News! We are excited to announce our brand new bodyweight
            exercise collection. <Link to="/exercises/">Check it out</Link>
          </p>
        </Banner>
        <Img
          fluid={data.image.childImageSharp.fluid}
          alt={`running man`}
          className="image"
        />
        <HeaderArticle
          title="How To Build Muscle With Calisthenics"
          link={"thoughts/calisthenics-building-muscle"}
          desc={`It is possible to build muscle without going to the gym! Calisthenics is an exercise routine that uses bodyweight to build muscle.`}
        />
      </div>
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
