import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled from "styled-components"

const HeaderArticleContainer = styled.div`
  @media (max-width: 800px) {
    width: 80%;
    margin: 10rem auto auto auto;
    padding-bottom: 0.5rem;
    float: none;
    z-index: 99;
    h2 {
      font-size: 1.4rem;
    }
    button {
      border: 2px solid white !important;
    }
  }
  z-index: 99;
  width: 30%;
  float: right;
  margin: 6rem 6rem 0 0;
  h2 {
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
  button {
    background: linear-gradient(
      270deg,
      rgba(27, 165, 131, 1) 0%,
      rgba(0, 128, 128, 1) 100%
    );
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.4rem;
    cursor: pointer;
    a:link {
      text-decoration: inherit;
      color: inherit;
      cursor: pointer;
    }
    a:visited {
      text-decoration: inherit;
      color: inherit;
      cursor: pointer;
    }
    &:hover {
      background: rgba(27, 165, 131, 1);
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      transform: scale(1.01);
    }
  }
`

const HeaderArticle: FunctionComponent<{
  title: string
  link: string
  desc?: string
}> = ({ title, link, desc }) => {
  return (
    <HeaderArticleContainer>
      <p>
        <code>Featured Article</code>
      </p>
      <h2>{title}</h2>
      <p>{desc}</p>
      <Link to={`/${link}/`}>
        <button>Read Article</button>
      </Link>
    </HeaderArticleContainer>
  )
}

export default HeaderArticle
