import styled from "styled-components"

export const HeaderContainer = styled.header`
  width: 100%;
  height: 80vh;
  padding-top: 4rem;

  .image {
    position: absolute !important;
    left: 8rem;
    z-index: 1;
    width: 50%;
    height: auto;
  }

  @media (max-width: 800px) {
    min-height: 75vh;
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(27, 165, 131, 1) 0%,
      rgba(0, 128, 128, 1) 100%
    );
    .image {
      left: 1rem;
      top: 2rem;
      z-index: 0;
      opacity: 0.7;
      margin: auto;
      width: 80%;
      height: auto;
    }
  }

  background: linear-gradient(
    270deg,
    rgba(234, 234, 234, 1) 0%,
    rgba(241, 241, 241, 1) 50%,
    rgba(27, 165, 131, 1) 50%,
    rgba(0, 128, 128, 1) 100%
  );
`
