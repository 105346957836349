import { graphql, Link, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import React, { FC } from "react"
import styled from "styled-components"
import { Anchor, Headline2 } from "../style/generic"

const ArticleHeaderGrid = styled.div`
  width: 75%;
  margin: auto;
  display: grid;

  @media (min-width: 801px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    h3 {
      a:link {
        text-decoration: inherit;
        color: inherit;
        cursor: pointer;
      }
      a:visited {
        text-decoration: inherit;
        color: inherit;
        cursor: pointer;
      }
    }
    div:first-child {
      grid-area: first;
      position: relative;
      overflow: hidden;
      align-items: flex-end;
      .image {
        object-fit: cover !important;
        min-width: 100% !important;
        position: absolute;
        z-index: -1;
      }
      .text {
        float: left;
        z-index: 100;
        position: absolute;
        left: 0.5rem;
        bottom: 0.5rem;
      }
      div {
        width: 75%;
      }
    }
    div:nth-child(2) {
      grid-area: second;
      align-items: flex-start;
    }
    div:nth-child(3) {
      grid-area: third;
      flex-direction: row-reverse;
    }
    grid-template-areas:
      "first first second second"
      "first first third third";
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    .text {
      font-size: 0.4rem;
      h3 {
        font-size: 0.8rem;
      }
      p {
        line-height: 1.2;
        font-size: 0.5rem;
      }
    }
  }
`

const ArticleContainer = styled.div<{ white?: boolean }>`
  display: flex;
  align-items: center;
  @media (min-width: 801px) {
    color: ${props => (props.white ? "white" : "inherit")};
  }
  &:hover {
    opacity: 0.8;
  }
  .image {
    margin-bottom: 0;
    width: 50%;
    max-width: 50%;
    height: auto;
  }

  h3 {
    margin-bottom: 0.2rem;
    font-size: 1rem;
    padding: 0 0.5rem;

    a:link {
      text-decoration: inherit;
      cursor: pointer;
    }
    a:visited {
      text-decoration: inherit;
      cursor: pointer;
    }
  }
  p {
    margin: 0;
    font-size: 0.6rem;
    line-height: 1.4;
    padding: 0 0.5rem;
  }
  .date {
    font-style: italic;
  }
  .ttr {
    background-color: #e6ebf1;
    padding: 0.1rem 0.4rem;
    color: black;
    font-size: 0.6rem;
    margin: 0 0.4rem;
  }
  &:hover {
    img {
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    }
  }
  .full-image {
    display: none;
  }
  .read-more {
    padding: 0.5rem;
  }
  @media (max-width: 800px) {
    .image {
      display: none;
    }
    .read-more {
    }
    .ttr {
      background-color: transparent;
      padding: 0.1rem 0.4rem;
      color: inherit;
      font-size: 0.4rem;
      margin: 0;
    }
    .text {
      .full-image {
        display: block;
        width: 100%;
        height: 4rem;
        background-color: red;
      }
    }
  }
`
const MobileHeaderImage = styled.div<{ src: string }>`
  display: none;
  @media (max-width: 800px) {
    display: block;
    width: 100%;
    height: 4rem;
    background-image: url(${props => props.src});
    background-size: cover;
    margin: 0.8rem 0;
  }
`

const Article: FC<{
  title: string
  date?: string
  desc: string
  img: FluidObject
  link: string
  white?: boolean
  ttr?: number
}> = ({ title, date, desc, img, link, white, ttr }) => {
  return (
    <ArticleContainer white={white}>
      <Img fluid={img} alt={title} className="image" />
      <div className="text">
        <MobileHeaderImage src={img.src} />
        <h3>
          <Link to={link}>{title}</Link>
        </h3>
        <p className="date">{date ? date : "This Month"}</p>
        <span className="ttr">{ttr} Minute read</span>
        <p>{desc}</p>
        <Anchor className="read-more" href={link}>
          Read
        </Anchor>
      </div>
    </ArticleContainer>
  )
}

const LatestArticles = () => {
  const data = useStaticQuery(graphql`
    query latestArticlesQuery {
      markdown: allMdx(
        sort: { order: DESC, fields: frontmatter___date }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              slug
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt(truncate: true)
            timeToRead
            id
          }
        }
      }
    }
  `)

  return (
    <section style={{ width: "100%", marginTop: "2rem" }}>
      <Headline2>Latest Articles</Headline2>
      <ArticleHeaderGrid>
        <Article
          link={data.markdown.edges[0].node.frontmatter.slug}
          title={data.markdown.edges[0].node.frontmatter.title}
          desc={data.markdown.edges[0].node.excerpt}
          date={data.markdown.edges[0].node.frontmatter.date}
          img={
            data.markdown.edges[0].node.frontmatter.featuredImage
              .childImageSharp.fluid
          }
          white={true}
          ttr={data.markdown.edges[0].node.timeToRead}
        />
        <Article
          link={data.markdown.edges[1].node.frontmatter.slug}
          title={data.markdown.edges[1].node.frontmatter.title}
          desc={data.markdown.edges[1].node.excerpt}
          date={data.markdown.edges[1].node.frontmatter.date}
          img={
            data.markdown.edges[1].node.frontmatter.featuredImage
              .childImageSharp.fluid
          }
          ttr={data.markdown.edges[1].node.timeToRead}
        />
        <Article
          link={data.markdown.edges[2].node.frontmatter.slug}
          title={data.markdown.edges[2].node.frontmatter.title}
          desc={data.markdown.edges[2].node.excerpt}
          date={data.markdown.edges[2].node.frontmatter.date}
          img={
            data.markdown.edges[2].node.frontmatter.featuredImage
              .childImageSharp.fluid
          }
          ttr={data.markdown.edges[2].node.timeToRead}
        />
      </ArticleHeaderGrid>
      {/*
      <ArticleGrid>
        <Article title="First Title" desc="My first article a brief desc man" />
        <Article
          title="Second Title"
          desc="My second article a brief desc man but htis time a bit longer for it"
          date="2020-11-11"
        />
        <Article
          title="Third Title"
          desc="My Third article a brief desc man but htis time a bit longer for it"
          date="2020-11-11"
        />
        <Article
          title="Fourth Title"
          desc="My Fourth article a brief desc man but htis time a bit longer for it"
          date="2020-11-11"
        />
        <Article
          title="Fifth Title"
          desc="My Fifth article a brief desc man but htis time a bit longer for it"
          date="2020-11-11"
        />
      </ArticleGrid>
      */}
    </section>
  )
}

export default LatestArticles
